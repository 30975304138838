//== DEFAULTS RAILS
import Rails from "@rails/ujs"
Rails.start()


// //== JQUERY
import $ from 'jquery'
window.$ = window.jQuery = $

//== BOOTSTRAP
import { Popper } from '@popperjs/core'
import * as bootstrap from 'bootstrap'


//
//
// FIXED HEADER
$(window).on('load scroll resize orientationchange', function () {
    if ($(document).scrollTop()) {
        let $header_image = $('#header_image');
        let $fix_it = $('#fix_it');
        let $height = $fix_it.height() + $header_image.height();

        $("#fix_it").addClass("fixed-top shadow bg-white");
        if ($header_image.height() > 0) {
            $header_image.css({ 'margin-top': $fix_it.height()+'px' });
        }
        else {
            // 48 sind mt-3 aus main
            $('#main').css({ 'margin-top': $height+48+'px' });
        }

    } else {
        $("#fix_it").removeClass("fixed-top shadow bg-white");
        $('#main').css({ 'margin-top': '0px' });
        $('#header_image').css({ 'margin-top': '0px' });
    }
});


document.addEventListener("DOMContentLoaded", function(event) {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })
});

document.addEventListener("DOMContentLoaded", function(event) {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {
            container: 'body'
        })
    })
});